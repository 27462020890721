import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import "../stylesheets/header.scss"

const Header = () => {
  const [menuOpen, setMenuOpen] = React.useState(false)
  const onClickMenu = () => {
    setMenuOpen(!menuOpen)
  }

  return (
    <header className="Header">
      <Link to="/" className="Header-title">
        <StaticImage
          src="../images/company_logo_header.png"
          alt=""
          placeholder=""
        />
      </Link>
      <ul className="Header-list">
        <li className="Header-listItem">
          <Link to="/philosophy" className="Header-listItemLink">
            企業理念
          </Link>
        </li>
        <li className="Header-listItem">
          <Link to="/company" className="Header-listItemLink">
            会社情報
          </Link>
        </li>
        <li className="Header-listItem">
          <Link to="/business" className="Header-listItemLink">
            事業内容
          </Link>
        </li>
        <li className="Header-listItem">
          <Link
            to="https://hokkaido.jobantenna.jp/at/15400/offer/"
            target="_blank"
            rel="noreferrer"
            className="Header-listItemLink"
          >
            採用情報
          </Link>
        </li>
        <li className="Header-listItem">
          <Link to="/contact" className="Header-listItemLink">
            お問い合わせ
          </Link>
        </li>
      </ul>
      <button className="Header-menuButton" onClick={onClickMenu}>
        <span className="Header-menuLine" data-open={menuOpen} />
        <span className="Header-menuLine" data-open={menuOpen} />
        <span className="Header-menuLine" data-open={menuOpen} />
      </button>
      <div className="Header-menu" data-open={menuOpen}>
        <ul className="Header-menu-list">
          <li className="Header-menu-listItem">
            <Link to="/" className="Header-menu-listItemLink">
              トップページ
            </Link>
          </li>
          <li className="Header-menu-listItem">
            <Link to="/philosophy" className="Header-menu-listItemLink">
              企業理念
            </Link>
          </li>
          <li className="Header-menu-listItem">
            <Link to="/company" className="Header-menu-listItemLink">
              会社情報
            </Link>
          </li>
          <li className="Header-menu-listItem">
            <Link to="/business" className="Header-menu-listItemLink">
              事業内容
            </Link>
          </li>
          <li className="Header-menu-listItem">
            <Link
              to="https://hokkaido.jobantenna.jp/at/15400/offer/"
              target="_blank"
              rel="noreferrer"
              className="Header-menu-listItemLink"
            >
              採用情報
            </Link>
          </li>
          <li className="Header-menu-listItem">
            <Link to="/contact" className="Header-menu-listItemLink">
              お問い合わせ
            </Link>
          </li>
          <li className="Header-menu-listItem">
            <Link to="/privacy" className="Header-menu-listItemLink">
              個人情報保護方針
            </Link>
          </li>
        </ul>
      </div>
    </header>
  )
}

export default Header

import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import "../stylesheets/footer.scss"

const Footer = () => (
  <footer className="Footer">
    <StaticImage
      src="../images/company_logo_footer.png"
      className="Footer-image"
      alt=""
      placeholder=""
    />
    <ul className="Footer-list">
      <li className="Footer-listItem">
        <Link to="/philosophy" className="Footer-listItemLink">
          企業理念
        </Link>
      </li>
      <li className="Footer-listItem">
        <Link to="/company" className="Footer-listItemLink">
          会社情報
        </Link>
      </li>
      <li className="Footer-listItem">
        <Link to="/business" className="Footer-listItemLink">
          事業内容
        </Link>
      </li>
      <li className="Footer-listItem">
        <Link to="/contact" className="Footer-listItemLink">
          お問い合わせ
        </Link>
      </li>
      <li className="Footer-listItem">
        <Link to="/privacy" className="Footer-listItemLink">
          個人情報保護方針
        </Link>
      </li>
    </ul>
    {/* <div className="Footer-snsArea">
      <Link to="#" className="Footer-snsLink">
        <StaticImage src="../images/twitter.png" alt="twitterアカウント" className="Footer-snsIcon" />
      </Link>
      <Link to="#" className="Footer-snsLink">
        <StaticImage src="../images/facebook.png" alt="facebookアカウント" className="Footer-snsIcon" />
      </Link>
    </div> */}
    <p className="Footer-copyright">
      copyright © DoshinInteractive, Inc. All Rights Reserved.
    </p>
  </footer>
)

export default Footer
